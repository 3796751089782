<template>
  <div
    v-if="!registered"
    style="margin-top:-30px;"
    class="mobileViewMarginTop"
  >
    <div style="font-size: 0.9rem;margin-top: 0px;" class="primary-colour">
      Register now to start playing the Grassroots Lottery!
    </div>
    <div
      v-if="errorMessageShowing != ''"
      style="border: solid 2px red;padding: 10px;margin-top: 15px;margin-bottom: 30px;"
    >
      <span style="color:red;">{{ errorMessageShowing }}</span>      
    </div>
    <validation-observer>
      <form autocomplete="off">
        <div class="md-form" style="margin-top:20px;">
          <div class="md-form form-sm m-0" style="height: 30px">
            <validation-provider
              mode="lazy"
              name="Forename"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:white;" />
              <mdb-input
                v-model="newUser.Forename"
                type="text"
                autocomplete="nu-for"
                label="Forename"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm m-0" style="height: 45px">
            <validation-provider
              mode="lazy"
              name="Surname"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:white;" />
              <mdb-input
                v-model="newUser.Surname"
                type="text"
                autocomplete="nu-sur"
                label="Surname"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form m-0" style="height: 40px">
            <validation-provider
              mode="lazy"
              name="Date of birth"
              rules="required"
              class="d-flex align-items-center"
            >
              <i class="fas fa-birthday-cake prefix sm" style="left:0;margin-top:23px;font-size:1rem;color:white;" />
              <mdb-input
                v-model="newUser.DD"
                class="mb-0"
                type="text"
                autocomplete="nu-day"
                label="DD"
                style="margin-top: 8px;margin-left:33px;margin-right:10px;width:30%;float:left;"
                size="sm"
                :max="31"
                :min="1"
              />
              <mdb-input
                v-model="newUser.MM"
                class="mb-0"
                type="text"
                autocomplete="nu-mth"
                label="MM"
                style="margin-top: 8px;margin-right:10px;width:27%;float:left;"
                size="sm"
                :min="1"
                :max="12"
              />
              <mdb-input
                v-model="newUser.YYYY"
                class="mb-0 mobile-date"
                type="text"
                auto-complete="nu-yer"
                label="YYYY"
                style="margin-top: 8px;width:30%;float:left;"
                size="sm"
                :min="1900"
                :max="2022"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm m-0" style="height: 30px">
            <validation-provider
              mode="lazy"
              name="First line of address"
              rules="required"
            >
              <i class="fas fa-home prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:white;" />
              <mdb-input
                v-model="newUser.Address"
                type="text"
                autocomplete="nu-add"
                label="First line of address"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm m-0" style="height: 30px">
            <validation-provider
              mode="lazy"
              name="Postcode"
              rules="required"
            >
              <i class="fas fa-map-pin prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:white;" />
              <mdb-input
                v-model="newUser.Postcode"
                type="text"
                autocomplete="nu-pcd"
                label="Postcode"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm m-0" style="height: 30px">
            <validation-provider
              mode="lazy"
              name="Telephone"
              rules="required"
            >
              <i class="fas fa-mobile prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:white;" />
              <mdb-input
                v-model="newUser.Telephone"
                type="text"
                autocomplete="nu-tel"
                label="Mobile"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm m-0" style="height: 30px">
            <validation-provider
              mode="lazy"
              name="Email"
              rules="required"
            >
              <i class="fas fa-envelope prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:white;" />
              <mdb-input
                v-model="newUser.Email"
                type="email"
                autocomplete="new-email"
                label="Email"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm m-0" style="height: 30px">
            <validation-provider
              ref="password"
              v-slot="{ errors }"
              mode="lazy"
              name="Password"
              rules="required|password-min-length-8-at-least-one-upper-and-one-lower"
            >
              <i class="fas fa-lock prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:white;" />
              <mdb-input
                v-model="newUser.Password"
                :type="visibility"
                autocomplete="new-password"
                name="new-password"
                label="Password"
                style="margin-left:33px;font-family:Arial, Helvetica, sans-serif !important;"
                size="sm"
                @change="validatePassword($event)"
              />
              <div style="position:absolute;right:0;top:9px;">
                <a v-if="visibility == 'password'" @click="toggleVisibility()"><i class="fas fa-eye grey-text" /></a>
                <a v-if="visibility == 'text'" @click="toggleVisibility()"><i class="fas fa-eye-slash grey-text" /></a>
              </div>
              <div
                v-if="errors[0]"
                style="font-size:0.7rem;margin-top:-10px;margin-bottom:10px;margin-left:33px;"
              >
                Please ensure your password is 8 characters long, with at least one lowercase letter, one uppercase letter, one number and one special character.
              </div>
              <div 
                v-else
                style="font-size:0.7rem;margin-top:-10px;margin-bottom:10px;margin-left:33px;"
              >
                Please ensure your password is 8 characters long, with at least one lowercase letter, one uppercase letter, one number and one special character.
              </div>
              <div style="margin-left:33px;border-bottom:solid 1px white;" />
            </validation-provider>
          </div>
          <div
            style="margin-top:85px;"
            class="md-form form-sm d-flex align-items-center"
            @click="toggleAgree()"
          >
            <mdb-input
              v-model="newUser.Agree"
              type="checkbox"
              class="mb-3 p-0 white-text"
              auto-complete="nu-agr"
              size="sm"
            />
            <span class="white-text" style="font-size: clamp(0.7rem, 1.5vw, 0.9rem);margin-top:0px;">I agree to the terms and conditions and confirm I am 18 years or older.</span>
          </div>
        </div>
      </form>
    </validation-observer>
    <div class="mt-3 d-flex justify-content-end">
      <mdb-btn
        v-show="!buttonClick"
        :disabled="!formIsValid"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        @click="signUp()"
      >
        REGISTER
      </mdb-btn>
      <mdb-btn
        v-show="buttonClick"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
  </div>
  <div 
    v-else
    style="margin-top:-30px;"
    class="mobileViewMarginTop"
  >
    <div style="font-size: 0.9rem;margin-top: 10px;text-align: center;" class="primary-colour">
      <div style="font-size:2rem;">
        Welcome!
      </div>
      <br><br>
      Your registration is complete and you've been automatically logged in to your new account.
      <br><br>
      <span style="color:white;">Please Note: a registration confirmation email will be sent to you shortly.</span>
      <br><br>
      Let's get started by creating your first Grassroots Hero!!!
      <br><br>
      <div @click="letsGo()">
        <AnimatedWord
          v-if="!mobileView"
          :word="word"
          style="font-size:4rem;cursor: pointer;" 
        />
      </div>
      <div style="margin-top:20px;">
        <mdb-btn
          v-if="mobileView"
          class="btn primary-btn btn-radius m-0 mobileOnly1"
          style="margin-top:20px;width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;cursor: pointer;"
          @click="letsGo()"
        >
          LETS GO
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { mapGetters } from 'vuex'
import loginService from '@/api-services/login.service'
import basketService from '@/api-services/basket.service'
import AnimatedWord from "@/components/UI/bouncingWordWrapper.vue"
import { WEB_URL } from '@/common/config'

extend('password-min-length-8-at-least-one-upper-and-one-lower', {
  getMessage (field, args) {
    console.log(field, args)
    return 'Your password must be 8 characters minimum, with at least one lowercase letter, one uppercase letter, and special character.'
  },
  validate (value) {
    return value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/)
  }
})
export default {
  name: 'Signup',
  components: {
    AnimatedWord
  },
  data () {
    return {
      passwordValid: false,
      word: 'LETS GO!',
      errorMessageShowing: '',
      visibility: 'password',
      buttonClick: false,
      newUser: {
        Forename: '',
        Surname: '',
        Email: '',
        DD: '',
        MM: '',
        YYYY: '',
        Address: '',
        Postcode: '',
        Telephone: '',
        Password: '',
        Agree: false,
        count: 0,
      },
      registered: false,
      clubs: []
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData',
      'currentBasket'
    ]),
    formIsValid () {
      return this.newUser.Forename !== '' && this.newUser.Surname !== '' && this.newUser.Email !== '' && this.newUser.DD.length === 2 && this.newUser.MM.length === 2 && this.newUser.YYYY.length === 4 && this.newUser.Address !== '' && this.newUser.Postcode !== '' && this.newUser.Telephone !== '' && this.passwordValid && this.newUser.Agree === true && this.selectedClub !== null
    }
  },
  methods: {
    letsGo () {
      if (this.currentBasket.length > 0) {
        this.$router.push('/basket/checkout')  
      } else {
        window.location.href = WEB_URL + "/play?page=1"
      }
    },
    toggleAgree () {
      if (this.newUser.Agree === true) {
        this.newUser.Agree = false
      } else {
        this.newUser.Agree = true
      }
    },
    login () {
      this.$emit('setPage', 'login')
    },
    toggleVisibility () {
      this.visibility = this.visibility === 'password' ? 'text' : 'password'
    },
    async validatePassword () {
      const ref = this.$refs.password
      const res = await ref.validate()
      this.passwordValid = res.valid
    },
    async addAllNewCardsToBackEnd () {
      for (const b of this.currentBasket) {
        await basketService.addToBasket(b, this.userData.access_token)
      }
    },
    signUp: async function () {
      this.registered = false
      this.errorMessageShowing = ''
      this.buttonClick = true
      var formData = new FormData()
      formData.append('Id', 0)
      formData.append('Forename', this.newUser.Forename)
      formData.append('Surname', this.newUser.Surname)
      formData.append('Email', this.newUser.Email)
      formData.append('DD', this.newUser.DD)
      formData.append('MM', this.newUser.MM)
      formData.append('YYYY', this.newUser.YYYY)
      formData.append('Address', this.newUser.Address)
      formData.append('Postcode', this.newUser.Postcode)
      formData.append('Telephone', this.newUser.Telephone)
      formData.append('Password', this.newUser.Password)
      formData.append('Agree', this.newUser.Agree)
      try {
        await loginService.signUp(formData)
        this.registered = true
        try {
          const res = await loginService.login(this.newUser.Email, this.newUser.Password)
          this.newUser = { Forename: '', Surname: '', Email: '', DD: '', MM: '', YYYY: '', Address: '', Postcode: '', Telephone: '', Password: '', Agree: false }
          this.$store.commit('storeUserData', res.data)
          this.$store.commit('storeRootData', true)
          this.$store.commit('storePanelViewedOnMobile', 'Left')

          if (res.data.led) {
            this.$store.commit('storeClubUrl', res.data.url)
          }

          this.buttonClick = false
          await this.addAllNewCardsToBackEnd()
          const basketRes = await basketService.getMyBasket(res.data.access_token)
          this.$store.commit('setBasket', basketRes.data)
          if (this.$route.query.redirectToPayment === 'true') {
            this.$router.push('/basket').catch(() => {})
          }
        } catch (err) {
          console.log('err in new user login', err, Object.assign({}, err))
        }
      } catch (err) {
        console.log(err, Object.assign({}, err))
        this.errorMessageShowing = Object.assign({}, err).response.data.Message
      }
      this.buttonClick = false
    }
  }
}
</script>

<style scoped>
@media (max-width: 576px) {
  .mobileViewMarginTop {
    margin-top:-60px;
  }
}
.form-check-label {
  color: white !important;
}
</style>
